import {Message, MessageBox} from "element-ui";

export function SuccessTip(message) {
    Message.success({
        showClose:true,
        message
    })
}

export function WarningTip(message) {
    Message.warning({
        showClose:true,
        message
    })
}

export function ErrorTip(message) {
    Message.error({
        showClose:true,
        message
    })
}

export function ConfirmTip(msg,type,showConfirmButton=true,showCancelButton= true) {
    if (!type) {type="fixed"}
    return new Promise((resolve, reject) => {
        MessageBox({
            title: `提示`,
            message: type === 'custom' ? msg : `此操作将永久删除该${msg}, 是否继续?`,
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            showCancelButton,
            showConfirmButton,
            closeOnClickModal:false,
            closeOnPressEscape:false,
            closeOnHashChange:false,
            showClose:false,
            type: 'warning',
        }).then(r => {
            resolve(r);
        }).catch(e => {
            reject(e);
        })
    })
}
