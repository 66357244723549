<template>
  <div class="deehow-navigation-container flexBetween">
    <div class="nav-left flexStart">
      <div class="icon flexCenter" @click="toLeft"><i class="el-icon-caret-left"></i></div>
      <div class="item-con" id="wheel">
        <div class="flexCenter item hvr-underline-from-center"
             style="border-left: 1px solid #f5f6f7;"
             v-for="(item,i) in navigationList" :key="i"
             @click.prevent.stop="toUrl(item)">
          {{ item.name }}
          <div class="close" @click.prevent.stop="deleteNav(item)">
            <i class="el-icon-circle-close"></i>
          </div>
          <div v-show="item.isAct" class="isact"></div>
        </div>
      </div>
    </div>
    <div class="nav-left flexStart">
      <div class="icon lBorder flexCenter" @click="toRight"><i class="el-icon-caret-right"></i></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "navigationBar",
  data() {
    return {
      icon: true
    }
  },
  computed: {
    ...mapGetters(["navigationList"])
  },
  methods: {
    toUrl(item) {
      this.$router.push(item.url);
      this.$store.dispatch("app/setNavigation", item);
    },
    deleteNav(item) {
      this.$store.dispatch("app/delNavigation", item).then(() => {
        if (item.url === this.$route.path) {
          if (this.navigationList.length < 1) {
            // this.$router.push("/indexSite");
          } else {
            this.$router.push(this.navigationList[this.navigationList.length - 1].url);
            this.navigationList[this.navigationList.length - 1].isAct = true;
          }
        }
      })
    },
    refreshNav() {
      this.icon = false;
      setTimeout(() => {
        this.icon = true;
      }, 2000)
    },
    toLeft() {
      let el = document.getElementById("wheel");
      el.scrollLeft -= 100;
    },
    toRight() {
      let el = document.getElementById("wheel");
      el.scrollLeft += 100;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/deehow-ui";
.lBorder {
  border-left: 1px solid #f5f6f7;
}
</style>
