/**
 * 评审状态
 * @param state
 */
export function getReviewState(state) {
    switch (state) {
        case 2:
            return '进行中'
        case 3:
            return '已关闭'
        case 4:
            return '到期自动关闭'
        default:
            return ''
    }
}

/**
 * 意见锁定状态
 * @param state
 */
export function getOpinionState(state) {
    switch (state) {
        case 1:
            return '未提交'
        case 2:
            return '新建'
        case 3:
            return 'Accept接受'
        case 4:
            return 'Discuss讨论'
        case 5:
            return '已修改'
        case 6:
            return '关闭'
        case 7:
            return '重启'
        case 8:
            return '待修改'
        case 9:
            return '评审遗留'
        case 10:
            return 'Reject拒绝'
        case 11:
            return 'Duplicate重复'
        default:
            return ''
    }
}

/**
 * 意见等级
 * @param grade
 */
export function getOpinionGrade(grade) {
    switch (grade) {
        case '1':
            return 'Suggest提示'
        case '2':
            return 'General一般'
        case '3':
            return 'Major严重'
        default:
            return ''
    }
}

/**
 * 获取文件状态
 * @param fileStatus
 * @returns {string}
 */
export function getFileStatusName(fileStatus) {
    switch (fileStatus) {
        case '0':
            return '工作中'
        case '1':
            return '已失效'
        case '2':
            return '已提交'
        default:
            return ''
    }
}

/**
 * 文档 产品状态
 * @param status
 * @returns {string}
 */
export function getInfoStatus(status) {
    if (typeof status === "string") {
        status = parseInt(status);
    }
    switch (status) {
        case -1:
            return "已取消";
        case 0:
            return "初稿";
        case 1:
            return "正在工作";
        case 2:
            return "审阅中";
        case 3:
            return "返回修改";
        case 4:
            return "申请中";
        case 9:
            return "已发布";
        case 10:
            return "禁用";
        case 11:
            return "废弃";
        case 12:
            return "设计";
        case 13:
            return "预发布";
        case 14:
            return "认证中";
        case 15:
            return "已认证";
        case 16:
            return "已批准";
        case 17:
            return "工程发布";
        default:
            return "暂无";
    }
}

/**
 * PLM状态
 * @param status
 * @returns {string}
 */
export function getPLMStatusMeth(status) {
    if (typeof status === "string") {
        status = parseInt(status);
    }
    switch (status) {
        case 13:
            return "预发布";
        case 9:
            return "已发布";
        case 17:
            return "工程发布";
        default:
            return "暂无";
    }
}
