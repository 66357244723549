import request from '@/utils/request'

/**
 * 查询安全策略配置列表
 */
export function getConfigList() {
    return request({
        url: '/sys/api/v1/sys-config/list',
        method: 'get'
    });
}

/**
 * 停用/启用某个安全策略配置
 */
export function setConfigEnable(id, enable) {
    return request({
        url: `/sys/api/v1/sys-config/enableConfig/${id}/${enable}`,
        method: 'post'
    });
}

/**
 * 更新安全策略配置
 */
export function updateConfig(params) {
    return request({
        url: `/sys/api/v1/sys-config/updateConfig`,
        method: 'post',
        data: JSON.stringify(params),
        headers: {
            'content-type': 'application/json'
        }
    });
}

/**
 * 查询某个安全策略配置的值
 */
export function getConfigByValue(params) {
    return request({
        url: '/sys/api/v1/sys-config/value',
        method: 'get',
        params
    });
}

/**
 * 查询个人的登录方式
 */
export function getUserLoginMode(params) {
    return request({
        url: '/sys/api/v1/user/loginMode',
        method: 'get',
        params
    });
}

/**
 * 更改个人的登录方式
 */
export function setUserLoginMode(id, mode) {
    return request({
        url: `/sys/api/v1/user/loginMode/${id}?mode=${mode}`,
        method: 'post',
    });
}

/**
 * 查询人员密级下的业务密级
 */
export function getAllSecrecyLevel() {
    return request({
        url: '/sys/api/v1/secrecyLevel/queryAll',
        method: 'get'
    });
}

/**
 * 修改人员密级下的业务密级
 */
export function updateSecrecyLevel(params) {
    return request({
        url: `/sys/api/v1/secrecyLevel/update`,
        method: 'post',
        data: JSON.stringify(params),
        headers: {
            'content-type': 'application/json'
        }
    });
}
