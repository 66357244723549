<template>
  <el-dialog title="修改密码" :close-on-click-modal="false" v-dialogDrag :visible.sync="dialogSetPassword" size="tiny"
             :modal="false">
    <forgot-pwd v-if="dialogSetPassword" ref="forgotPwdRef" :dialogSetPassword="dialogSetPassword"></forgot-pwd>
  </el-dialog>
</template>

<script>
import ForgotPwd from "@/components/sitePage/setPassword/ForgotPwd";

export default {
  name: "ForgotPwdLayout",
  components: {ForgotPwd},
  data() {
    return {
      dialogSetPassword: false
    }
  },
  methods: {
    init() {
      this.dialogSetPassword = true;
    }
  }
}
</script>

<style scoped>

</style>
