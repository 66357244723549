const oaRouter = [
    // 元器件
    {
        path: "/oaApplyData/:processSource/:prcessStatus/:processKey/:processName/:processInstanceId/:id/:applyType?/:activityId?/:applyReason?/:approveTime?/:bussionKey?/:createBy?/:createTime?/:createUserName?/:curNodeName?/:goodsType?/:partNumber?/:procDefId?/:processGoodsId?/:processVersion?/:suspended?/:userId?/:userName?",
        name: '元器件流程',
        menuName: '元器件流程详情',
        component: () => import("@/components/library/process/OaApplyData"),
    },
    // 产品
    {
        path: "/oaProduct/:processSource/:id/:from/:processInstanceId/:processVersion/:templateId/:currentActivityId/:currentUserName/:startTime/:taskId?",
        name: '产品流程',
        menuName: '产品流程详情',
        component: () => import("@/views/psa/teamWork/productManage/index"),
    },
    // 文档
    {
        path: "/oaDocument/:processSource/:id/:from/:processInstanceId/:processVersion/:templateId/:currentActivityId/:currentUserName/:startTime/:taskId?",
        name: '文档流程',
        menuName: '文档流程详情',
        component: () => import("@/views/psa/teamWork/documentManage/index"),
    }
]


export default oaRouter;
