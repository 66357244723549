import request from '@/utils/request'

/**
 * 获取sys_site_config的所有信息
 */
export function querySiteConfig() {
  return request({
    url: '/sys/api/v1/siteConfig/list',
    method: 'get'
  });
}

/**
 * 创建、修改站点名称
 */
export function updateSiteName(siteName) {
  return request({
    url: `/sys/api/v1/siteConfig/updateSiteName?value=${siteName}`,
    method: 'post',
  });
}