// 产品标识
const productCategory = "product_category";
// 文档标识
const documentCategory = "document_category";
// 文档流程标识
const documentFlow = "documentFlow";
// 产品流程标识
const productFlow = "productFlow";
// 变更单流程标识
const alterFlow = "alterFlow";
// 文档下载申标识
const documentDownloadKey = "documentDownloadKey";
// 文档发布通用审阅单标识
const documentCommonBpms = "documentCommonBpms";
// 产品发布通用审阅单标识
const productCommonBpms = "productCommonBpms";
// 产品创建审阅单
const productCreateFlow = "productCreateFlow";
// 文件下载申请审阅单
const documentFileDownloadBpms = "documentFileDownloadBpms";
// 流程数组
const processKeyList = [
    {
        des: '文档发布审阅单',
        value: documentFlow
    },
    {
        des: '产品发布审阅单',
        value: productFlow
    },
    {
        des: '产品创建审阅单',
        value: productCreateFlow
    },
    /*{
        des: '变更请求申请单',
        value: alterFlow
    },
    {
        des: '文档下载申请单',
        value: documentDownloadKey
    },*/
    {
        des: '文档发布通用审阅单',
        value: documentCommonBpms
    },
    {
        des: '产品发布通用审阅单',
        value: productCommonBpms
    },
    {
        des: '文件下载申请审阅单',
        value: documentFileDownloadBpms
    }
]
// 文件类型
const objectType = {
    "文档":2,
    "产品":1,
    "元器件":0,
    "变更":4,
}
// 替代类型
const replaceType = {
    "局部替代":1,
    "全局替代":0,
    "无":2,
}
// 产品状态筛选用
const productStatusOptions = [
    {
        label: "全部",
        type: "info",
        value: "",
    },
    {
        label: "已取消",
        type: "",
        value: -1,
    },
    {
        label: "初稿",
        type: "",
        value: 0,
    },
    {
        label: "正在工作",
        type: "warning",
        value: 1,
    },
    {
        label: "审阅中",
        type: "danger",
        value: 2,
    },
    {
        label: "返回修改",
        type: "warning",
        value: 3,
    },
    {
        label: "申请中",
        type: "",
        value: 4,
    },
    {
        label: "已发布",
        type: "success",
        value: 9,
    },
    {
        label: "禁用",
        type: "danger",
        value: 10,
    },
    {
        label: "废弃",
        type: "info",
        value: 11,
    },
    {
        label: "设计",
        type: "info",
        value: 12,
    },
    {
        label: "预发布",
        type: "info",
        value: 13,
    },
    {
        label: "认证中",
        type: "info",
        value: 14,
    },
    {
        label: "已认证",
        type: "info",
        value: 15,
    },
    {
        label: "已批准",
        type: "info",
        value: 16,
    },
    {
        label: "工程发布",
        type: "info",
        value: 17,
    },
]
// 文档状态筛选
const documentStatusOptions = [
    {
        label: "全部",
        type: "info",
        value: "",
    },
    {
        label: "初稿",
        type: "",
        value: 0,
    },
    {
        label: "正在工作",
        type: "warning",
        value: 1,
    },
    {
        label: "审阅中",
        type: "danger",
        value: 2,
    },
    {
        label: "返回修改",
        type: "warning",
        value: 3,
    },
    {
        label: "已发布",
        type: "success",
        value: 9,
    },
    {
        label: "已取消",
        type: "success",
        value: -1,
    },
    {
        label: "由产品创建",
        type: "success",
        value: -2,
    },
    {
        label: "禁用",
        type: "danger",
        value: 10,
    },
    {
        label: "废弃",
        type: "info",
        value: 11,
    },
]

export default {
    productCategory,
    documentCategory,
    documentFlow,
    productFlow,
    alterFlow,
    documentDownloadKey,
    processKeyList,
    objectType,
    replaceType,
    documentCommonBpms,
    productCommonBpms,
    productCreateFlow,
    documentFileDownloadBpms,
    productStatusOptions,
    documentStatusOptions,
}
