import Layout from "@/views/layout/library/index";

const home = [
  {
    path:"/",
    name: "Home",
    meta:{
      login:"yes"
    },
    redirect: '/indexSite',
  },
  {
    path: '/indexSite',
    name: '首页',
    meta:{
      login:"yes"
    },
    component: () => import('@/views/homePage/sitePage/indexSite.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta:{
      login:"no"
    },
    component: () => import('@/views/homePage/login/index'),
    redirect: '/login/logHome',
    children: [
      {
        path: 'logHome',
        name: '欢迎登录',
        meta:{login:"no"},
        component: () => import('@/views/homePage/login/logHome'),
      },
    ]
  },
  {
    path:"/devCompare/:partition",
    name: "devCompare",
    meta:{
      login:"yes"
    },
    component: () => import('@/components/library/partLibrary/DeviceCompare'),
  },
  {
    path: '/partsloader',
    name: 'partsloader',
    meta:{
      login:"no"
    },
    component: () => import('@/components/library/partLibrary/partsLoader'),
  },
  // partsloader用
  {
    path: '/partsReview',
    name: 'partsReview',
    meta:{
      login:"no"
    },
    component: () => import('@/views/library/designReviewManage/designReview/partsReview'),
  },
  // partsloader用
  {
    path: '/partsPreferLibrary',
    name: 'partsPreferLibrary',
    meta:{
      login:"no"
    },
    component: () => import('@/views/library/conponent/preferencesLibrary/index'),
  },
  // partsloader用
  {
    path: '/partsProductDesign',
    name: 'partsProductDesign',
    meta:{
      login:"no"
    },
    component: () => import('@/views/library/productDesignManage/productDesign/index'),
  },
  {
    path: '/componentsUpdate',
    name: 'componentsUpdate',
    redirect: "/componentsUpdate",
    component: Layout,
    children: [
      {
        meta:{login:"yes"},
        path:"/componentsUpdate",
        component: () => import('@/components/library/partLibrary/componentsUpdate'),
      }
    ]
  },
  {
    path: '/componentsLeadin',
    name: 'componentsLeadin',
    redirect: "/componentsLeadin",
    component: Layout,
    children: [
      {
        meta:{login:"yes"},
        path:"/componentsLeadin",
        component: () => import('@/components/library/partLibrary/componentsLeadin'),
      }
    ]
  },
  {
    path: '/supplierAttributes',
    name: 'supplierAttributes',
    redirect: "/supplierAttributes",
    component: Layout,
    children: [
      {
        meta:{login:"yes"},
        path:"/supplierAttributes",
        component: () => import('@/components/library/supplier/supplierAttributes'),
      }
    ]
  },
  {
    path: '/materialList',
    name: 'materialList',
    redirect: "/materialList",
    component: Layout,
    children: [
      {
        meta:{login:"yes"},
        path:"/materialList",
        component: () => import('@/components/library/material/materialList'),
      }
    ]
  },
  {
    path: '/messageDetail',
    name: 'messageDetail',
    redirect: "/messageDetail",
    component: Layout,
    children: [
      {
        meta:{login:"yes"},
        path:"/messageDetail",
        component: () => import('@/components/center/system/messageDetail'),
      }
    ]
  },
  {
    path: '/partSet',
    name: 'partSet',
    redirect: "/partSet/partColor",
    component: Layout,
    children: [
      {
        meta:{login:"yes"},
        path:"/partSet/partColor",
        component: () => import('@/components/library/partLibrary/partColor'),
      },
      {
        meta:{login:"yes"},
        path:"/partSet/partSecurity",
        component: () => import('@/components/library/partLibrary/partSecurity'),
      },
      {
        meta:{login:"yes"},
        path:"/partSet/partSameShow",
        component: () => import('@/components/library/partLibrary/partSameShow'),
      },
      {
        meta:{login:"yes"},
        path:"/partSet/partState",
        component: () => import('@/components/library/partLibrary/partState'),
      },
      {
        meta:{login:"yes"},
        path:"/partSet/partMaintenance",
        component: () => import('@/components/library/partLibrary/partMaintenance'),
      },
      {
        meta:{login:"yes"},
        path:"/partSet/partPreferred",
        component: () => import('@/components/library/partLibrary/partPreferred'),
      }
    ]
  },
  {
    path: '/updateCompare',
    name: 'updateCompare',
    meta:{
      login:"yes"
    },
    component: () => import('@/components/library/partLibrary/updateCompare'),
  },
  {
    path: '/libraryGoodInfo',
    name: '器件详情',
    meta:{
      login:"yes"
    },
    component: () => import('@/components/library/partLibrary/LibraryGoodInfo'),
  },
  {
    path: '/ticketLogin',
    name: '登录',
    meta:{
      login:"no"
    },
    component: () => import('@/components/redirect/ticketLogin'),
  },
  {
    path: '/resetPassword',
    name: '重置密码',
    meta:{
      login:"yes"
    },
    component: () => import("@/components/sitePage/setPassword/ForgotPwd"),
  }
]
export default home;
