const partLibrary = [
    // partsloader用
    {
        path: "/engineeringPreferencesLibrary/:wbsId",
        name: '元器件优选清单',
        menuName: '元器件优选清单',
        meta:{login:"no"},
        component: () => import("@/components/library/partLibrary/EngineeringPreferencesLibrary"),
    },
]

export default partLibrary;
