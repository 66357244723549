import Cookies from 'js-cookie'
import store from '@/store'
import router from "../router";
import {getUrlParams} from "./tools";
const TokenKey = 'Admin-Token';
import {loginFor709} from "@/api/library/login";
import {ErrorTip} from "./element";

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function exitApp() {
  store.dispatch('user/LogOut').then(() => {
    window.localStorage.clear();
    window.sessionStorage.clear();
    Cookies.remove(TokenKey)
    window.location.href = location.protocol + "//" +location.host+"/#/login";
    window.location.reload();
    sessionStorage.setItem("loginCase","/");
  })
}

/**
 * 709 路由重定向获取ticket - router中监听
 * @param route
 */
export function getTicket(route) {
  // 已知ticket中不会有/
  const watchPath = window.edmConfig.loginConfig.watchPath;
  // 处理 http://localhost:9000/#/login/logHome?ticket=aaabbb
  let ticket = route?.query?.ticket;
  let path = route.path;
  let fun = () => {
    store.dispatch("app/setTicket", ticket).then(() => {
      // console.log(store.state.app.ticket)
      store.dispatch('user/LogOut', null).then(() => {
        loginFor709(store.state.app.ticket).then(res => {
          setTimeout(() => {
            oa709Login();
          },100)
        })
      })
    })
  }
  if (ticket && ticket.includes("/")) {
    // 处理 http://localhost:9000/#?ticket=aaabbb/login/logHome
    ticket = ticket.split("/")[0];
    let pa = "";
    route.query.ticket.split("/").forEach((p,i) => {
      if (i > 0) {
        pa += '/' + p;
      }
    })
    path = pa;
  }
  if (!ticket) {
    // 处理 http://localhost:9000/?ticket=aaabbb#/login/logHome
    const params = getUrlParams(location.search);
    if (params && params.ticket) {
      ticket = params.ticket;
    }
  }
  // console.log(watchPath)
  // console.log(path)
  // console.log(ticket)
  if (ticket && !store.state.app.ticket) {
    if (watchPath) {
      if (watchPath === path) {
        fun();
      } else {
        console.log("配置文件路径与重定向路径不匹配");
      }
    } else {
      fun();
    }
  }
}

/**
 * oa跳转登录
 */
export function oa709Login() {
  store.dispatch("user/SetAppLogin", "").then(() => {
    store.dispatch("user/GetUserInfo", "").then(() => {
      if (sessionStorage.getItem("routerPath")) {
        const params = JSON.parse(sessionStorage.getItem("routerPath"));
        if (params.path === "/") {
          return router.push("/");
        }
        setTimeout(() => {
          let path = "";
          if (params.path === "/oaProduct" || params.path === "/oaDocument") {
            path = `${params.path}/${params.processSource}/${params.id}/${params.from}/${params.processInstanceId}/${params.processVersion}/${params.templateId}/${params.currentActivityId}/${params.currentUserName}/${params.startTime}/${params.taskId}`;
          } else if (params.path === "/oaApplyData") {
            path = `${params.path}/${params.processSource}/${params.prcessStatus}/${params.processKey}/${params.processName}/${params.processInstanceId}/${params.id}/${params.applyType || "applyType"}/${params.activityId || "activityId"}/${params.applyReason || "applyReason"}/${params.approveTime || "approveTime"}/${params.bussionKey || "bussionKey"}/${params.createBy || "createBy"}/${params.createTime || "createTime"}/${params.createUserName || "createUserName"}/${params.curNodeName || "curNodeName"}/${params.goodsType || "goodsType"}/${params.partNumber || "partNumber"}/${params.procDefId || "procDefId"}/${params.processGoodsId || "processGoodsId"}/${params.processVersion || "processVersion"}/${params.suspended || "suspended"}/${params.userId || "userId"}/${params.userName || "userName"}`;
          } else {
            path = "/404";
          }
          path = path.replaceAll("#","%23");
          router.replace({path});
          /*let routeData = router.resolve({
            path,
          });
          window.open(routeData.href, "_blank");*/
          sessionStorage.removeItem("routerPath");
        },500)
      } else {
        setTimeout(() => {
          location.replace(location.origin);
        },500)
      }
    })
  })
}
