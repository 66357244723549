// set function parseTime,formatTime to filter
export { parseTime, formatTime } from '@/utils'
import dayjs from 'dayjs';
import {getInfoStatus,getPLMStatusMeth,getReviewState,getOpinionState,getOpinionGrade,getFileStatusName} from "@/utils/enum";

import Vue from 'vue'
// 将时间戳格式化为时间字符串的全局过滤器
Vue.filter('timestampFormat', (timestamp) => {
  timestamp = timestamp.replace(new RegExp(/-/gm), "/");
  if (timestamp == null) {
    return "--年--月--日";
  }
  timestamp = Date.parse(timestamp) / 1000
  function zeroize(num) {
    return (String(num).length == 1 ? '0' : '') + num;
  }
  var curTimestamp = parseInt(new Date().getTime() / 1000); //当前时间戳
  var timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数
  var curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
  var tmDate = new Date(timestamp * 1000);  // 参数时间戳转换成的日期对象

  var Y = tmDate.getFullYear(), m = tmDate.getMonth() + 1, d = tmDate.getDate();
  var H = tmDate.getHours(), i = tmDate.getMinutes(), s = tmDate.getSeconds();

  if (timestampDiff < 60) { // 一分钟以内
    return "刚刚";
  } else if (timestampDiff < 3600) { // 一小时前之内
    return Math.floor(timestampDiff / 60) + "分钟前";
  } else if (curDate.getFullYear() == Y && curDate.getMonth() + 1 == m && curDate.getDate() == d) {
    return '今天' + zeroize(H) + ':' + zeroize(i);
  } else {
    var newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
    if (newDate.getFullYear() == Y && newDate.getMonth() + 1 == m && newDate.getDate() == d) {
      return '昨天' + zeroize(H) + ':' + zeroize(i);
    } else if (curDate.getFullYear() == Y) {
      return zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
    } else {
      return Y + '年' + zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
    }
  }
})
Vue.filter('parseTime', (time, cFormat) => {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
})

/**
 * 转换日期类型
 */
Vue.filter('timeFormatter', (time, format) => {
  return time ? dayjs(time).format(format) : "";
})

function pluralize(time, label) {
  if (time === 1) {
    return time + label
  }
  return time + label + 's'
}

export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time)
  if (between < 3600) {
    return pluralize(~~(between / 60), ' minute')
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), ' hour')
  } else {
    return pluralize(~~(between / 86400), ' day')
  }
}

/* 数字 格式化*/
export function numberFormatter(num, digits) {
  const si = [
    { value: 1E18, symbol: 'E' },
    { value: 1E15, symbol: 'P' },
    { value: 1E12, symbol: 'T' },
    { value: 1E9, symbol: 'G' },
    { value: 1E6, symbol: 'M' },
    { value: 1E3, symbol: 'k' }
  ]
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
    }
  }
  return num.toString()
}

export function toThousandslsFilter(num) {
  return (+num || 0).toString().replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ','))
}

/**
 * 转换日期类型 2019-08-18T16:00:00.000Z
 */
function returnTwo(t) {
  return ('0' + t).toString().slice(-2);
}
Vue.filter('filterTime', function (value) {
  var t = new Date(value).getTime();
  var time = new Date(t);
  var Y = time.getFullYear();
  var M = returnTwo(time.getMonth());
  var D = returnTwo(time.getDate());
  var HH = returnTwo(time.getHours());
  var MM = returnTwo(time.getMinutes());
  var SS = returnTwo(time.getSeconds());
  return `${Y}-${M}-${D} ${HH}:${MM}:${SS}`
})

/**
 * 文件大小转换
 */
Vue.filter("fileSizeFilter", function (item) {
  if (item > 1024 * 1024 * 1024) {
    return (item / (1024 * 1024 * 1024)).toFixed(2) + 'GB';
  } else if (item > 1024 * 1024) {
    return (item / (1024 * 1024)).toFixed(2) + 'MB';
  } else if (item > 1024) {
    return (item / 1024).toFixed(2) + 'KB';
  } else {
    return item + 'B';
  }
})

/**
 * 流程状态转换
 */
Vue.filter("processStatusFilter", function (num) {
  switch (num) {
    case '1':
      return "正在处理";
    case '2':
      return "已废弃";
    case '3':
      return "驳回";
    case '4':
      return "已结束";
    case '5':
      return "已撤回";
    default:
      return "未知";
  }
})

/**
 * PLM状态转换
 */
Vue.filter("getPLMStatus", getPLMStatusMeth);

/**
 * 文档/产品/变更状态
 */
Vue.filter("getStatus", getInfoStatus);

/**
 * 控件类型
 */
Vue.filter("getComponentType", function (v) {
  switch (v) {
    case 1:
      return "输入框";
    case 2:
      return "选择框";
    case 3:
      return "复选框";
    case 4:
      return "数字输入";
    case 5:
      return "日期选择";
    case 6:
      return "滑动条";
    case 7:
      return "文件上传";
    case 8:
      return "用户选择";
    case 9:
      return "分类选择";
    case 10:
      return "部门选择";
    case 11:
      return "型号选择";
    case 12:
      return "字典码表";
    default:
      return "暂无";
  }
})

/**
 * 是否
 */
Vue.filter("getIfType", function (v) {
  switch (v) {
    case 'Y':
      return "是";
    case 'N':
      return "否";
    case 1:
      return "是";
    case 0:
      return "否";
    default:
      return "";
  }
})

/**
 * 男女
 */
Vue.filter("getSex", function (v) {
  switch (v) {
    case 1:
      return "男";
    case 2:
      return "女";
    default:
      return "未知";
  }
})

/**
 * 评审状态
 */
Vue.filter("getReviewState", getReviewState)

/**
 * 意见锁定状态
 */
Vue.filter("getOpinionState", getOpinionState)

/**
 * 意见等级
 */
Vue.filter("getOpinionGrade", getOpinionGrade)

/**
 * 处理ef65 - ef69
 */
Vue.filter("upJsonPars",function (item) {
  if (item) {
    try {
      let arr = JSON.parse(item);
      let nameArr = arr.map((item) => item.fileName);
      return nameArr.join(",");
    } catch {
      return "";
    }
  } else {
    return "";
  }
})

/**
 * 文件状态
 */
Vue.filter("getFileStatusName", getFileStatusName)
