<template>
  <div style="background: #ffffff;padding: 0;display: flex;justify-content: center;align-items: center;"
       :style="{height:$route.path === '/resetPassword' ? 100+'vh' : ''}">
    <div class="rest-pwd">
      <div style="padding:20px">
        <h4 v-if="$route.path === '/resetPassword'" style="color: #333;font-weight: 600;margin-bottom:20px;font-size:23px">
          修改密码
        </h4>
        <div class="kuang">
          <el-form autoComplete="on" :model="form" :rules="loginRules" ref="form" label-position="left"
                   label-width="100px" class="card-box login-form">
            <el-form-item prop="oldPassword" label="旧密码" style="margin-bottom: 20px;">
              <el-input name="oldPassword" :type="isShow ? 'password' : 'text'" v-model.trim="form.oldPassword" autoComplete="new-password"
                        placeholder="请输入旧密码">
                <template slot="suffix">
                  <img style="width: 15px;" @click="isShow = !isShow" alt="图片"
                       :src="isShow ? require('../../../assets/img/homePage/EyeInvisible.png') : require('../../../assets/img/homePage/mimakejian.png')"/>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password" label="新密码" style="margin-bottom: 20px;">
              <template v-if="pwdRule === '1' || !pwdRule">
                <!-- 复杂密码(至少包含一个小写字母和一个大写字母和一位数字，长度至少8位) -->
                <password-test @password="getpass" :dialogSetPassword="dialogSetPassword"></password-test>
              </template>
              <template v-else-if="pwdRule === '2'">
                <!-- 简单密码(密码长度6位即可) -->
                <password-test-simple @password="getpass" :dialogSetPassword="dialogSetPassword"></password-test-simple>
              </template>
            </el-form-item>
            <el-form-item prop="password1" label="确认密码" style="margin-bottom: 20px;">
              <el-input name="password1" :type="isShow1 ? 'password' : 'text'" v-model.trim="form.password1" autoComplete="new-password"
                        placeholder="两次密码请保持一致">
                <template slot="suffix">
                  <img style="width: 15px;" @click="isShow1 = !isShow1" alt="图片"
                       :src="isShow1 ? require('../../../assets/img/homePage/EyeInvisible.png') : require('../../../assets/img/homePage/mimakejian.png')"/>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" style="width:100%;" :loading="loading" @click.native.prevent="resetPassword">
                确认修改
              </el-button>
            </el-form-item>
            <el-form-item v-if="$route.path === '/resetPassword'">
              <el-button type="primary" plain style="width:100%;margin-top: 5px;" :disabled="loading"
                         @click="backLogin">
                返回登录页
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PasswordTest from "@/components/sitePage/setPassword/PasswordTest";
import {setPassword} from "@/api/library/login";
import {exitApp} from "@/utils/auth";
import {getConfigByValue} from "@/api/center/securityPolicy";
import PasswordTestSimple from "@/components/sitePage/setPassword/PasswordTestSimple";

export default {
  name: "ForgotPwd",
  components: {
    PasswordTestSimple,
    PasswordTest
  },
  props: {
    dialogSetPassword: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShow1: true,
      isShow: true,
      checked: false,
      loading: false,
      info1: false,
      form: {
        password: "",
        password1: "",
        oldPassword: ""
      },
      ifpasstest: false,
      loginRules: {
        oldPassword: [
          {
            required: true,
            trigger: "blur",
            message: "请输入旧密码",
          }
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            validator: this.validatePass1,
          }
        ],
        password1: [
          {
            required: true,
            trigger: ["change","blur"],
            validator: this.validatePass,
          }
        ],
      },
      pwdRule: ""
    }
  },
  watch: {
    dialogSetPassword: {
      handler(v) {
        if (!v && this.$route.path !== "/resetPassword") {
          this.cancelSet();
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.getPwdRule();
  },
  methods: {
    cancelSet() {
      this.form.password = "";
      this.form.password1 = "";
      this.form.oldPassword = "";
      this.$refs.form.clearValidate();
    },
    resetPassword() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.ifpasstest) {
            return this.$message.warning("密码格式不正确");
          }
          this.loading = true;
          let par = {
            oldPassword: this.form.oldPassword,
            password: this.form.password,
          }
          setPassword(par).then(res => {
            this.$confirm("修改成功,请使用新密码登录",'提示', {
              confirmButtonText: 'OK',
              type: 'success',
              closeOnClickModal: false,
              closeOnPressEscape:false,
              closeOnHashChange:false,
              showCancelButton: false,
              showClose: false
            }).then(res => {
              exitApp();
            })
          }).finally(() => {
            this.loading = false;
          })
        }
      })
    },
    validatePass1(rule, value, callback) {
      if (value === '' || value == null) {
        callback(new Error("请输入新密码"));
      } else {
        let str = /^(?=.*?[A-Za-z])(?!.*[\u4E00-\u9FA5])(?!.*\s)(?=.*?[0-9]).{8,}$/;
        if (this.pwdRule === "1" && (!str.test(value) || !this.ifpasstest)) {
          callback(new Error("密码格式不正确"));
        } else if (this.pwdRule === "2" && !this.ifpasstest) {
          callback(new Error("密码格式不正确"));
        } else if (this.form.password1 !== '') {
          this.$refs.form.validateField('password1');
        }
        callback();
      }
    },
    validatePass(rule, value, callback) {
      if (value === '' || value == null) {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次密码请保持一致"));
      } else {
        callback();
      }
    },
    getpass(data1, data2) {
      this.form.password = data1;
      this.ifpasstest = data2;
    },
    backLogin() {
      exitApp();
    },
    getPwdRule() {
      getConfigByValue({configKey: 'sys.user.pwdComplexity'}).then(res => {
        this.pwdRule = res.data;
      })
    }
  },
};
</script>

<style lang="scss" scoped>
//@import "~@/styles/mixin.scss";
//@import "~@/styles/variables.scss";
h4 {
  text-align: center;
}

.kuang {
  padding: 20px;
  // border: 1px solid #eee;
}

.rest-pwd {
  width: 550px;
  margin: 0px auto;
  background: #fff;
  /*@include fun-media(pad) {
    width: 100%;
  }*/
}

.coloract {
  color: green;
}
</style>
